<template>
    <v-row no-gutters>
        <v-col cols="12">
            <AccountBar :organizationId="$route.params.organizationId" :account="account" class="mb-6" v-if="account"></AccountBar>
            <AccessDeniedOverlay v-if="forbiddenError"></AccessDeniedOverlay>
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-organization-list' }">Organizations</router-link>
                    <template v-if="organization">
                    &gt; <router-link :to="{ name: 'organization-dashboard', params: { realm: this.$route.params.organizationId } }">{{ organization.name }}</router-link>
                    </template>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card class="pa-0" v-if="order">
                        <v-app-bar color="green darken-2" dark flat dense>
                            <v-app-bar-title>Order</v-app-bar-title>
                            <v-spacer/>
                            <span style="font-size: 0.7em;" class="text-end">Account ID: {{ accountIdText }}<br>Order ID: {{ orderIdText }}</span>
                            <!-- TODO: enable invite new users only for organizations with business or enterprise add-ons; organizations on the essential plan have only one admin -->
                            <!-- <v-btn icon :to="{ name: 'organization-invite-user', params: { organizationId: this.$route.params.organizationId } }">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width></font-awesome-icon>
                            </v-btn> -->
                        </v-app-bar>
                        <v-simple-table dense>
                            <template #default>
                                <tbody>
                                    <tr>
                                        <th style="min-width: 100px;">Created on</th>
                                        <td style="width: 100%;">
                                            {{ formatDate(order.created_on) }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Amount</th>
                                        <td style="width: 100%;">
                                            {{ formatAmount(order.currency, order.amount_csu) }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Status</th>
                                        <td style="width: 100%;">
                                            <!-- TODO: paid and activated should be two different status fields.... and is 'activated' for a service the same as 'fulfilled' for a one-time purchase? -->
                                            <template v-if="order.status === 'paid'">
                                                <span class="font-weight-bold">Paid</span>
                                                <!-- TODO: instead of just marking it fulfilled, take user to a page where they can check off every item in the order as they fulfill it, then 'done' button returns here -->
                                                <v-btn x-small class="ml-2" color="blue darken-2 white--text" @click="fulfillOrder">Mark Fulfilled</v-btn>
                                                <!-- TODO: the refundOrder function needs to have a confirmation so we don't refund because of a stray click! -->
                                                <v-btn x-small class="ml-2" color="amber" @click="refundOrder">Refund</v-btn>
                                            </template>
                                            <template v-if="order.status === 'activated'">
                                                <span class="font-weight-bold">Activated</span>
                                                <v-btn text @click="reactivate" color="green darken-2">Reactivate</v-btn>
                                            </template>
                                            <template v-if="order.status === 'processed'">
                                                Processed
                                            </template>
                                            <template v-if="order.status === 'completed'">
                                                Completed
                                            </template>
                                            <template v-if="order.status === 'cancelled'">
                                                Cancelled
                                            </template>
                                            <template v-if="!['paid', 'activated', 'processed', 'completed', 'cancelled'].includes(order.status)">
                                                <v-btn x-small class="ml-2" color="amber" @click="cancelOrder">Cancel</v-btn>
                                            </template>
                                        </td>
                                    </tr>
                                    <tr v-if="order.name">
                                        <th style="min-width: 150px;">Name</th>
                                        <td style="width: 100%;">
                                            {{ order.name }}
                                        </td>
                                    </tr>
                                    <tr v-if="order.email">
                                        <th style="min-width: 150px;">Email</th>
                                        <td style="width: 100%;">
                                            {{ order.email }}
                                        </td>
                                    </tr>
                                    <tr v-if="order.billing_address">
                                        <th style="min-width: 150px;">Billing address</th>
                                        <td style="width: 100%;">
                                            <!-- TODO: we need a component to display the address -->
                                            {{ JSON.stringify(order.billing_address) }}
                                        </td>
                                    </tr>
                                    <tr v-if="order.shipping_address">
                                        <th style="min-width: 150px;">Shipping address</th>
                                        <td style="width: 100%;">
                                            <!-- TODO: we need a component to display the address -->
                                            {{ JSON.stringify(order.shipping_address) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-card-text  style="border-top: 1px solid #888888;">
                            <!-- <p>Created on {{ formatDate(order.created_on) }}</p>
                            <p>Order content:</p> -->
                        </v-card-text>
                        <v-simple-table>
                            <template #default>
                                <thead>
                                    <tr>
                                        <th>Product ID</th>
                                        <th>Name</th>
                                        <th>Qty</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, idx) in order.content" :key="idx">
                                        <td>
                                            {{ item.product_id }}
                                        </td>
                                        <td>
                                            {{ item.name }}
                                        </td>
                                        <td>
                                            {{ item.quantity }}
                                        </td>
                                        <td>
                                            {{ formatAmount(item.currency, item.subtotal_csu) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import { fromCurrencyAmountCSU } from '@libertyio/currency-util-js';
import AccountBar from '@/components/AccountBar.vue';
import AccessDeniedOverlay from '@/components/AccessDeniedOverlay.vue';

function formatId(input) {
    const parts = [];
    let i = 0;
    while (i < input.length) {
        const part = input.substr(i, 5);
        parts.push(part);
        i += 5;
    }
    return parts.join('-');
}

export default {
    components: {
        AccountBar,
        AccessDeniedOverlay,
    },
    data: () => ({
        organization: null,
        order: null,
        error: null,
        forbiddenError: false,
    }),
    computed: {
        ...mapState({
            organization: (state) => state.organization,
        }),
        orderIdText() {
            return formatId(this.order.id);
        },
        accountIdText() {
            return formatId(this.order.account_id);
        },
        account() {
            return this.order?.account;
        },
    },
    methods: {
        async loadOrder() {
            try {
                this.$store.commit('loading', { loadOrder: true });
                const response = await this.$client.organization(this.$route.params.organizationId).accountOrder.get({ id: this.$route.params.orderId, include: 'account' });
                if (response) {
                    this.order = response;
                } else {
                    this.order = null;
                }
            } catch (err) {
                console.error('loadOrder failed', err);
            } finally {
                this.$store.commit('loading', { loadOrder: false });
            }
        },
        viewOrderLink(item) {
            return { name: 'organization-view-order', params: { organizationId: this.$route.params.organizationId, orderId: item.id } };
        },
        formatDate(timestamp) {
            return new Date(timestamp).toLocaleDateString();
        },
        formatAmount(currency, amountCSU) {
            return fromCurrencyAmountCSU(currency, amountCSU).toStringWithCurrencySymbol();
        },
        orderStatusDisplay(status) {
            if (status === 'paid') {
                return 'Paid, waiting to be processed..';
            }
            if (status === 'activated') {
                return 'Processing...';
            }
            if (status === 'completed') {
                return 'Fulfilled';
            }
            return null;
        },
        /**
         * Reactivate means to try starting the fulfillment process again.
         * This is useful when an error occurred during fulfillment, so the
         * organization admin can press the reactivate button and try it again
         * after developers fix the issue.
         */
        async reactivate() {
            try {
                this.$store.commit('loading', { reactivateOrder: true });
                const response = await this.$client.organization(this.$route.params.organizationId).accountOrder.check({ id: this.$route.params.orderId }, { item: 'reactivate' });
                if (response?.status === 'processed') {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Processed order' });
                } else if (response?.status === 'activated') {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Re-activated' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to re-activate order' });
                }
                this.loadOrder();
            } catch (err) {
                console.error('reactivateOrder failed', err);
            } finally {
                this.$store.commit('loading', { reactivateOrder: false });
            }
        },
        async cancelOrder() {
            try {
                this.$store.commit('loading', { cancelOrder: true });
                const response = await this.$client.organization(this.$route.params.organizationId).accountOrder.rpc({ id: this.$route.params.orderId }, { action: 'cancel' });
                if (response?.status === 'cancelled') {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Cancelled order' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to cancel order' });
                }
                this.loadOrder();
            } catch (err) {
                console.error('cancelOrder failed', err);
            } finally {
                this.$store.commit('loading', { cancelOrder: false });
            }
        },
        async refundOrder() {
            try {
                /* instead of trying to refund the order directly, we redirect admin to list of payments on same account...  there could be one payment for the order, or there could be multiple...  TODO: we need a way to assocaite a payment to an order or invoice in a searchable way... right now the payment.info has order_id
                this.$store.commit('loading', { refundOrder: true });
                const response = await this.$client.organization(this.$route.params.organizationId).accountOrder.rpc({ id: this.$route.params.orderId }, { action: 'refund' });
                if (response?.status === 'cancelled') {
                    // TODO: provide a link to the refund
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Cancelled order' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to cancel order' });
                }
                this.loadOrder();
                */
                this.$router.push({ name: 'organization-search-payment', params: { organizationId: this.$route.params.organizationId }, query: { account_id: this.account.id, order_id: this.order.id } });
            } catch (err) {
                console.error('refundOrder failed', err);
            } finally {
                this.$store.commit('loading', { refundOrder: false });
            }
        },
        async fulfillOrder() {
            try {
                this.$store.commit('loading', { fulfillOrder: true });
                const response = await this.$client.organization(this.$route.params.organizationId).accountOrder.rpc({ id: this.$route.params.orderId }, { action: 'fulfill' });
                if (response?.status === 'completed') {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Marked order as completed' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to mark order as completed' });
                }
                this.loadOrder();
            } catch (err) {
                console.error('fulfillOrder failed', err);
            } finally {
                this.$store.commit('loading', { fulfillOrder: false });
            }
        },
    },
    mounted() {
        this.loadOrder();
    },
};
</script>
