<template>
    <v-card flat>
        <v-toolbar flat color="grey lighten-5">
            <v-toolbar-title>
                <template v-if="account">
                    <font-awesome-icon :icon="['fas', 'file-alt']"></font-awesome-icon>
                    <span class="ml-4">{{ account.name }}</span>
                </template>
                <template v-else>
                    <!-- NOTE: we use grey here so that administrators can tell the difference between an unknown account (grey) and a known account whose name is "Unknown account" (black) -->
                    <font-awesome-icon :icon="['fas', 'file-alt']" color="grey"></font-awesome-icon>
                    <span class="ml-4 grey--text">Unknown account</span>
                </template>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-btn icon>
                <font-awesome-icon :icon="['fas', 'globe']"></font-awesome-icon>
            </v-btn> -->
            <!-- <template #extension v-if="accountId">
                <span style="font-size: 0.75em;">
                    <router-link :to="{ name: 'account-view-domain', params: { accountId, domain } }">Overview</router-link>
                    <span class="mx-4"></span>
                    <router-link :to="{ name: 'account-edit-dns', params: { accountId, domain } }">DNS Records</router-link>
                </span>
            </template> -->
            <span style="font-size: 0.75em;" v-if="account">
                <template v-if="$route.name === 'organization-view-account'">
                    <span class="font-weight-bold">Overview</span>
                </template>
                <template v-else>
                    <router-link :to="{ name: 'organization-view-account', params: { organizationId, accountId: account.id } }">Overview</router-link>
                </template>
                <!-- <span class="mx-4"></span>
                <router-link :to="{ name: 'account-edit-dns', params: { accountId, productId } }">DNS Records</router-link> -->
            </span>
        </v-toolbar>
    </v-card>
</template>
<script>
export default {
    props: ['organizationId', 'account'],
};
</script>
